import {
  IConfigurationParams,
  ConfigurationNames,
} from '../../../../graphql/configuration/models/configuration.models';
  
export const getVideoCallEnabled = (configuration: IConfigurationParams[]): boolean => {
  let result: boolean = false;
  
  const videoCallparams = configuration.find((
    params,
  ) => params.name === ConfigurationNames.videoCall);
  
  if (videoCallparams) {
    result = JSON.parse(videoCallparams.configuration).enabled;
  }
  
  return result;
};
