import { useEffect } from 'react';
import { useQuery } from '@apollo/client';

import { GET_CONFIGURATION } from '../../../graphql/configuration/queries/get-configuration';
import { IConfigurationSettingsProps } from './configuration-settings.models';
import {
  setIsLocatorIconsCampaign,
  setIsFreepacks,
  setIsCaptcha,
  setC2CMode,
  setIsVideoCall,
} from '../../../graphql/configuration/configuration.cache';
import {
  getFreepacksEnabled,
  getLocatorIconsEnabled,
  getCaptchaEnabled,
  getC2CMode,
  getVideoCallEnabled,
} from './utils';
import { setOrderModalToStorage } from '../../../utils/order/set-orders-status-to-storage';

export const ConfigurationSettings = ({ children }: IConfigurationSettingsProps): JSX.Element => {
  const { data } = useQuery(GET_CONFIGURATION, {
    context: { clientName: 'anonymous' },
  });

  useEffect(() => {
    if (data && data?.configuration?.length) {
      setIsLocatorIconsCampaign(getLocatorIconsEnabled(data.configuration));
      setIsFreepacks(getFreepacksEnabled(data.configuration));
      setIsCaptcha(getCaptchaEnabled(data.configuration));
      setC2CMode(getC2CMode(data.configuration));
      setOrderModalToStorage(getC2CMode(data.configuration));
      setIsVideoCall(getVideoCallEnabled(data.configuration));
    }
  }, [data]);

  return children as JSX.Element;
};
